import request from "@/utils/request";

// 油脂统计
export function oilAll(areaCode, query) {
  return request({
    url: "/bi/oil/stat/all/" + areaCode,
    method: "GET",
    params: query
  });
}

// 抽油监测
export function supervise(areaCode, query) {
  return request({
    url: "/bi/oil/stat/supervise/" + areaCode,
    method: "GET",
    params: query
  });
}

// 收运情况
export function collect(areaCode, query) {
  return request({
    url: "/bi/oil/stat/collect/" + areaCode,
    method: "GET",
    params: query
  });
}

// 送厂情况
export function factory(areaCode, query) {
  return request({
    url: "/bi/oil/stat/factory/" + areaCode,
    method: "GET",
    params: query
  });
}
