<template>
  <div>
    <div class="time-flex">
      日期范围<el-date-picker
        v-model="timeValue"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        size="small"
        value-format="yyyy-MM-dd"
        @change="timeChange"
      >
      </el-date-picker>
      <div
        class="btn-time"
        :class="colorActive == 3 ? '' : 'btn-time-active'"
        @click="monthClick(3)"
      >
        近3月
      </div>
      <div
        class="btn-time"
        :class="colorActive == 6 ? '' : 'btn-time-active'"
        @click="monthClick(6)"
      >
        近6月
      </div>
      <div
        class="btn-time"
        :class="colorActive == 12 ? '' : 'btn-time-active'"
        @click="monthClick(12)"
      >
        近一年
      </div>
    </div>

    <div class="mying-box">
      <div class="mying-box-title">油脂统计</div>
      <div>
        <div class="process-box">
          <div class="process-box-item">
            <div class="car">
              <div>购新油{{ grease.num4 }}吨</div>
              <img src="@/assets/images/statistical/car.png" alt="" />
            </div>
          </div>
          <div class="process-box-item">
            <img
              class="xian"
              src="@/assets/images/statistical/green.png"
              alt=""
            />
            <div class="process-box-text bgimg1">
              <div class="num">
                {{ grease.num1 }} <span class="num-sec">吨</span>
              </div>
              <div>未收运</div>
            </div>
            <div class="car">
              <div>已收运{{ grease.num5 }}吨</div>
              <img src="@/assets/images/statistical/car.png" alt="" />
            </div>
          </div>
          <div class="process-box-item">
            <img
              class="xian"
              src="@/assets/images/statistical/yellow.png"
              alt=""
            />
            <div class="process-box-text bgimg2">
              <div class="num">
                {{ grease.num2 }} <span class="num-sec">吨</span>
              </div>
              <div>未送厂</div>
            </div>
            <div class="car car2">
              <div>已送厂{{ grease.num6 }}吨</div>
              <img src="@/assets/images/statistical/car.png" alt="" />
            </div>
          </div>
          <div class="process-box-item">
            <img
              class="xian"
              src="@/assets/images/statistical/blue.png"
              alt=""
            />
            <div class="process-box-text bgimg3">
              <div class="num">
                {{ grease.num3 }} <span class="num-sec">吨</span>
              </div>
              <div>未处置</div>
            </div>
            <div class="car car1">
              <div>已处置{{ grease.num7 }}吨</div>
              <img src="@/assets/images/statistical/car.png" alt="" />
            </div>
          </div>
        </div>
        <div style="text-align:center">{{ grease.time }}的流向</div>
      </div>
    </div>
    <div class="mying-box">
      <div class="mying-box-title">油脂采购</div>
      <div v-if="tableData.list.length !== 0">
        <div class="form-search flex-v-center">
          <el-form
            :inline="true"
            :model="formSelect"
            class="demo-form-inline"
            size="small"
          >
            <el-form-item label="收运类型">
              <el-select v-model="formSelect.type" placeholder="请选择">
                <el-option label="油脂" value="1"></el-option>
                <el-option label="固渣" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收运状态">
              <el-select v-model="formSelect.status" placeholder="请选择">
                <el-option label="运输中" value="1"></el-option>
                <el-option label="已完成" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary">查询</el-button>
            </el-form-item>
          </el-form>
          <div>导出报表</div>
        </div>
        <div>
          <el-table :data="tableData.list" style="width: 100%">
            <el-table-column label="采购日期"> </el-table-column>
            <el-table-column label="商户名称"> </el-table-column>
            <el-table-column label="经营类型"> </el-table-column>
            <el-table-column label="所属区域"> </el-table-column>
            <el-table-column label="渠道"> </el-table-column>
            <el-table-column label="类型"> </el-table-column>
            <el-table-column label="品牌"> </el-table-column>
            <el-table-column label="重量（L）"> </el-table-column>
            <el-table-column label="采购价格（元/L）"> </el-table-column>
            <el-table-column label="票据图片"> </el-table-column>
          </el-table>
          <div class="flex-v-center" style="margin-top: 10px">
            <div></div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="tableData.total"
              :current-page="tableData.currentpage"
              :page-size="tableData.pagesize"
              prev-text="上一页"
              next-text="下一页"
              hide-on-single-page
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div v-else class="empty">
        <img src="@/assets/images/merchants_details/empty.png" alt="" />
        <div>暂无数据</div>
      </div>
    </div>
    <div class="mying-box">
      <div class="mying-box-title">抽油监测</div>
      <!-- <div v-if="tableData1.list.length !== 0"> -->
      <div class="form-search flex-v-center">
        <el-form
          :inline="true"
          :model="formSelect1"
          class="demo-form-inline"
          size="small"
        >
          <el-form-item label="收运类型">
            <el-select
              v-model="formSelect1.type"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in selectList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="搜索">
            <el-input
              v-model="formSelect1.seach"
              clearable
              placeholder="请输入商户名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search1">查询</el-button>
          </el-form-item>
        </el-form>
        <div>导出报表</div>
      </div>
      <div>
        <el-table :data="tableData1.list" style="width: 100%">
          <el-table-column prop="oilTime" label="抽油时间"> </el-table-column>
          <el-table-column prop="merchantName" label="商户名称">
          </el-table-column>
          <el-table-column prop="bizType" label="经营类型"> </el-table-column>
          <el-table-column prop="weight" label="重量/kg"> </el-table-column>
          <el-table-column prop="area" label="所属区域"> </el-table-column>
        </el-table>
        <div class="flex-v-center" style="margin-top: 10px">
          <div></div>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="tableData1.total"
            :current-page="tableData1.currentpage"
            :page-size="tableData1.pagesize"
            @current-change="handleCurrentChange1"
            prev-text="上一页"
            next-text="下一页"
            hide-on-single-page
          >
          </el-pagination>
        </div>
      </div>
      <!-- </div> -->
      <!-- <div v-else class="empty">
        <img src="@/assets/images/merchants_details/empty.png" alt="" />
        <div>暂无数据</div>
      </div> -->
    </div>
    <div class="mying-box">
      <div class="mying-box-title">收运情况</div>
      <!-- <div v-if="tableData2.list.length !== 0"> -->
      <div class="form-search flex-v-center">
        <el-form
          :inline="true"
          :model="formSelect2"
          class="demo-form-inline"
          size="small"
        >
          <el-form-item label="收运类型">
            <el-select
              v-model="formSelect2.type"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in selectList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="搜索">
            <el-input
              v-model="formSelect2.seach"
              clearable
              placeholder="请输入商户名称/收运单位"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search2">查询</el-button>
          </el-form-item>
        </el-form>
        <div>导出报表</div>
      </div>
      <div>
        <el-table :data="tableData2.list" style="width: 100%">
          <el-table-column prop="collectTime" label="收油时间">
          </el-table-column>
          <el-table-column prop="merchantName" label="商户名称">
          </el-table-column>
          <el-table-column prop="bizType" label="经营类型"> </el-table-column>
          <el-table-column prop="weight" label="重量/kg"> </el-table-column>
          <el-table-column prop="area" label="所属区域"> </el-table-column>
          <el-table-column prop="collectorName" label="收运单位">
          </el-table-column>
          <el-table-column prop="employeeName" label="收运人员">
          </el-table-column>
        </el-table>
        <div class="flex-v-center" style="margin-top: 10px">
          <div></div>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="tableData2.total"
            :current-page="tableData2.currentpage"
            :page-size="tableData2.pagesize"
            @current-change="handleCurrentChange2"
            prev-text="上一页"
            next-text="下一页"
            hide-on-single-page
          >
          </el-pagination>
        </div>
      </div>
      <!-- </div>
      <div v-else class="empty">
        <img src="@/assets/images/merchants_details/empty.png" alt="" />
        <div>暂无数据</div>
      </div> -->
    </div>
    <div class="mying-box">
      <div class="mying-box-title">送厂情况</div>
      <!-- <div v-if="tableData3.list.length !== 0"> -->
      <div class="form-search flex-v-center">
        <el-form
          :inline="true"
          :model="formSelect3"
          class="demo-form-inline"
          size="small"
        >
          <el-form-item label="搜索">
            <el-input
              v-model="formSelect3.seach"
              clearable
              placeholder="请输入收运单位/处置企业"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search3">查询</el-button>
          </el-form-item>
        </el-form>
        <div>导出报表</div>
      </div>
      <div>
        <el-table :data="tableData3.list" style="width: 100%">
          <el-table-column prop="factoryTime" label="送厂日期">
          </el-table-column>
          <el-table-column prop="collectorName" label="收运单位">
          </el-table-column>
          <el-table-column prop="employeeName" label="运输人员">
          </el-table-column>
          <el-table-column prop="weight" label="重量/kg"> </el-table-column>
          <el-table-column prop="factoryName" label="处置企业">
          </el-table-column>
        </el-table>
        <div class="flex-v-center" style="margin-top: 10px">
          <div></div>
          <el-pagination
            background
            layout="prev, pager, next"
            :total="tableData3.total"
            :current-page="tableData3.currentpage"
            :page-size="tableData3.pagesize"
            @current-change="handleCurrentChange3"
            prev-text="上一页"
            next-text="下一页"
            hide-on-single-page
          >
          </el-pagination>
        </div>
      </div>
      <!-- </div>
      <div v-else class="empty">
        <img src="@/assets/images/merchants_details/empty.png" alt="" />
        <div>暂无数据</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { oilAll, supervise, collect, factory } from "@/api/grease_statistics";
export default {
  data() {
    return {
      query: {
        offsetMonth: 3,
        beginDate: "",
        endDate: ""
      },
      areaCode: "",
      timeValue: "",
      colorActive: 3,
      grease: {
        num1: 0,
        num2: 0,
        num3: 0,
        num4: 0,
        num5: 0,
        num6: 0,
        num7: 0,
        time: ""
      },
      selectList: [
        {
          label: "火锅",
          value: 1
        },
        {
          label: "中餐",
          value: 2
        },
        {
          label: "食堂",
          value: 3
        },
        {
          label: "西餐",
          value: 4
        },
        {
          label: "面食",
          value: 5
        },
        {
          label: "小吃",
          value: 6
        },
        {
          label: "料理",
          value: 7
        },
        {
          label: "其他",
          value: 8
        }
      ],
      formSelect1: {
        type: "",
        seach: ""
      },
      formSelect2: {
        type: "",
        seach: ""
      },
      formSelect3: {
        seach: ""
      },
      formSelect: {},
      tableData: {
        total: 0,
        currentpage: 5,
        pagesize: 1,
        list: []
      },
      param1: {},
      tableData1: {
        total: 0,
        currentpage: 1,
        pagesize: 5,
        list: []
      },
      param2: {},
      tableData2: {
        total: 0,
        currentpage: 1,
        pagesize: 5,
        list: []
      },
      param3: {},
      tableData3: {
        total: 0,
        currentpage: 1,
        pagesize: 5,
        list: []
      }
    };
  },
  mounted() {
    this.monthClick(3);
  },
  methods: {
    // 选择日期范围
    timeChange(e) {
      if (e != null) {
        this.query = {
          beginDate: e[0],
          endDate: e[1]
        };
        this.colorActive = 0;
        this.getoilAll(this.areaCode, this.query);
        this.getsupervise();
        this.getcollect();
        this.getfactory();
      } else {
        this.colorActive = 3;
        this.monthClick(3);
      }
    },
    monthClick(data) {
      this.colorActive = data;
      this.timeValue = "";
      this.query = {
        offsetMonth: data
      };
      this.getoilAll(this.areaCode, this.query);
      this.getsupervise();
      this.getcollect();
      this.getfactory();
    },
    getoilAll(areaCode, query) {
      oilAll(areaCode, query).then(res => {
        // console.log(res);

        this.grease = {
          num1: res.data.notCollectOil,
          num2: res.data.notToFactoryOil,
          num3: res.data.notDisposalOil,
          num4: res.data.newOil,
          num5: res.data.collectOil,
          num6: res.data.factoryOil,
          num7: res.data.disposalOil,
          time: res.data.beginDate + "至" + res.data.endDate
        };
      });
    },
    // 抽油监测
    getsupervise() {
      this.param1 = this.query;
      this.param1.pageSize = this.tableData1.pagesize;
      this.param1.pageNum = this.tableData1.currentpage;
      this.param1.isAsc = "asc";
      supervise(this.areaCode, this.param1).then(res => {
        // console.log(res);
        this.tableData1.list = res.rows;
        this.tableData1.total = res.total;
      });
    },
    handleCurrentChange1(val) {
      this.tableData1.currentpage = val;
      this.getsupervise();
    },
    search1() {
      this.param1.bizType = this.formSelect1.type;
      this.param1.condition = this.formSelect1.seach;
      this.getsupervise();
    },
    // 收运情况
    getcollect() {
      this.param2 = this.query;
      this.param2.pageSize = this.tableData2.pagesize;
      this.param2.pageNum = this.tableData2.currentpage;
      this.param2.isAsc = "asc";
      collect(this.areaCode, this.param2).then(res => {
        // console.log(res);
        // this.tableData1 = res.data;
        this.tableData2.list = res.rows;
        this.tableData2.total = res.total;
      });
    },
    handleCurrentChange2(val) {
      this.tableData2.currentpage = val;
      this.getcollect();
    },
    search2() {
      this.param2.bizType = this.formSelect2.type;
      this.param2.condition = this.formSelect2.seach;
      this.getcollect();
    },
    // 送厂情况
    getfactory() {
      this.param3 = this.query;
      this.param3.pageSize = this.tableData3.pagesize;
      this.param3.pageNum = this.tableData3.currentpage;
      this.param3.isAsc = "asc";
      factory(this.areaCode, this.query).then(res => {
        // console.log(res);
        // this.tableData1 = res.data;
        this.tableData3.list = res.rows;
        this.tableData3.total = res.total;
      });
    },
    handleCurrentChange3(val) {
      this.tableData3.currentpage = val;
      this.getfactory();
    },
    search3() {
      this.param3.condition = this.formSelect3.seach;
      this.getfactory();
    }
  }
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}
.flex-v-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mying-box {
  padding: 20px;
  background: rgba(54, 59, 110, 0.3);
  border: 1px solid #3d4283;
  border-radius: 5px;
  margin-bottom: 20px;
}
.mying-box-title {
  font-size: 18px;
  color: rgba(93, 229, 245, 1);
  margin-bottom: 20px;
}

.process-box {
  width: 100%;
  // background: #666;
  height: 280px;
  display: flex;
  margin-bottom: 30px;
}
.process-box-item {
  flex: 25%;
  // border-left: 1px dashed #ff0000;
  position: relative;
}
.process-box-item:after {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(
    to left,
    #00ccff 0%,
    #00ccff 50%,
    transparent 50%
  );
  background-size: 8px 1px;
  background-repeat: repeat-x;
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
}
.xian {
  position: absolute;
  bottom: -8px;
  left: 0;
  height: 75%;
  z-index: 1;
  // width: 5px;
}
.process-box-text {
  width: 180px;
  height: 75px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 0;
  left: -80px;
  padding-left: 80px;
  padding-bottom: 5px;
}
.bgimg1 {
  background: url("~@/assets/images/statistical/kuai_1.png") no-repeat;
  background-size: contain;
}
.bgimg2 {
  background: url("~@/assets/images/statistical/kuai_2.png") no-repeat;
  background-size: contain;
}
.bgimg3 {
  background: url("~@/assets/images/statistical/kuai_3.png") no-repeat;
  background-size: contain;
}
.num {
  font-size: 1.375rem;
}
.num-sec {
  font-size: 0.875rem;
}
.car {
  position: absolute;
  bottom: 0;
  left: 0;
  animation: example 10s infinite;
  width: 100%;
}

@keyframes example {
  from {
    padding-left: 0;
  }
  to {
    padding-left: calc(90% - 100px);
  }
}
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 20px;
  }
}
.time-flex {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.btn-time {
  background: radial-gradient(#3c427d, #666ca7);
  border: 1px solid #666ca7;
  border-radius: 4px;
  padding: 4px 6px;
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
}
.btn-time:hover {
  color: #ccc;
  border: 1px solid rgba($color: #666ca7, $alpha: 0.8);
}
.btn-time-active {
  background: #191c3c;
  border: 1px solid #323877;
}
.el-range-editor {
  margin: 0 20px;
}
</style>
